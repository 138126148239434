import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from "react-helmet";
import Footer from "../../Components/Footer";
import { useParams } from "react-router-dom";
import PagesHeader from '../../Components/PagesHeader';
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();

function Pages() {
    const didMountRef = useRef(true);
    const { slug } = useParams();
    const [pageData, setPageData] = useState({})
    const [headerImage, setHeaderImage] = useState("")
    const [pageContent, setPageContent] = useState('');

    useEffect(() => {
        if (didMountRef.current) {
          const dataString = {
            "page_url": slug
          }
    
          apiServices.pagePostRequest(dataString).then(res => {
            if (res.data.status === "success") {
              if (res.data.pageData != null) {
                setPageData(res.data.pageData)
                setPageContent(res.data.pageData.page_content)
                setHeaderImage(res.data.page_header_url)
              }
            
            }
          })
    
        }
        didMountRef.current = false;
      });


  return (
    <>
        <Helmet>
            <title>{pageData.page_meta_title}</title>
            <meta name="description" itemprop="description" content={pageData.page_meta_desc} />
            <meta name="keywords" content={pageData.page_meta_keyword} />
            <link rel="canonical" href={window.location.href} />
            <meta name="robots" content="index,follow" />
            <meta name="author" content="Roti Corner" />
            <meta property="og:title" content={pageData.page_meta_title} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Roti Corner" />
            {pageData.page_header_image != null ? <meta property="og:image" content={headerImage + pageData.page_header_image} />
              : <meta property="og:image" content={'/assets/img/logo.png'} />}
            <meta property="og:url" content={window.location.href} />
            <meta property="og:description" content={pageData.page_meta_desc} />
            <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@Roti Corner" />
            <meta name="twitter:title" content={pageData.page_meta_title} />
            <meta name="twitter:description" content={pageData.page_meta_desc} />
            {pageData.page_header_image != null ? <meta property="twitter:image" content={headerImage + pageData.page_header_image} />
              : <meta property="twitter:image" content={'/assets/img/logo.png'} />}
            <meta name="twitter:url" content={window.location.href} />
            <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
          </Helmet>
    <PagesHeader/>
    <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{backgroundImage: `url(${headerImage + pageData.page_header_image})`,backgroundSize:'cover',backgroundPosition:'100%'}}>
    
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                {pageData.page_sub_header != null ? <h5>{pageData.page_sub_header}</h5> : ""}
                  {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}

                </div>
            </div>
        </div>
    </div>

    {pageContent != null ? <div dangerouslySetInnerHTML={{ __html: pageContent }}></div> : ""}
    <Footer/>
    </>
  )
}

export default Pages