import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import PagesHeader from "../../Components/PagesHeader";
import { ApiService } from "../../Components/Services/apiservices";
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import { Helmet } from "react-helmet";
import moment from 'moment';
const apiServices = new ApiService()
function GalleryDetailPage() {
  const didMountRef = useRef(true)
  const { slug } = useParams()
  const [gallaryCategory, setGallaryCategory] = useState([])
  const [gallaryImageUrl, setGallaryImageUrl] = useState("")
  const [categoryData, setCategoryData] = useState({})
  const [pageData, setPageData] = useState({})
  const [headerImage, setHeaderImage] = useState("")
  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        "category_slug": slug
      }
      apiServices.categorywisegallaryPostRequest(dataString).then(res => {
        if (res.data.status == "success") {
          setGallaryCategory(res.data.galleryData)
          setGallaryImageUrl(res.data.gallery_url)
          setCategoryData(res.data.category)
        }
      })
      const dataString2 = {
        "page_url": "gallery"
      }
      apiServices.pagePostRequest(dataString2).then(res => {
        if (res.data.status === "success") {
          if (res.data.pageData != null) {
            setPageData(res.data.pageData)
            setHeaderImage(res.data.page_header_url)
          }
        }
      })
    }
    didMountRef.current = false;
  }, []);
  return (
    <>
      <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc} />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Roti Corner" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Roti Corner" />
        {pageData.page_header_image != null ? <meta property="og:image" content={headerImage + pageData.page_header_image} />
          : <meta property="og:image" content={'/assets/img/logo.png'} />}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Roti Corner" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc} />
        {pageData.page_header_image != null ? <meta property="twitter:image" content={headerImage + pageData.page_header_image} />
          : <meta property="twitter:image" content={'/assets/img/logo.png'} />}
        <meta name="twitter:url" content={window.location.href} />
        <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
      </Helmet>
      <PagesHeader />

      <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3"

        style={{ backgroundImage: `url(/assets/img/reservation.jpg)`, backgroundSize: 'cover', backgroundPosition: '100%' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 caption mt-90">
              <h5><a href="/gallary">Roti Corner</a> </h5>
              {categoryData.category_name != null ? <h1>{categoryData.category_name}</h1> : ""}
            </div>
          </div>
        </div>
      </div>

      <section className="rooms1 section-padding bg-blck-light" data-scroll-index="2">
        <div className="container">
          <div className="row">

            {gallaryCategory.map((value) => (
              <div className="col-md-4">
                <div className="item">
                  <div className="position-re o-hidden">
                    <LightGallery
                      speed={500}
                      plugins={[lgThumbnail, lgZoom]}>
                      <a href={gallaryImageUrl + value.gallery_image}>
                        <img src={value.gallery_image != null ? gallaryImageUrl + value.gallery_image : "/assets/img/defaultimage.png"} alt="" />
                        <div className="con">

                        </div>
                      </a>
                    </LightGallery>
                  </div>
                </div>
              </div>))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}
export default GalleryDetailPage