import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from "react-helmet";
import Footer from "../../Components/Footer";
import { useParams } from "react-router-dom";
import PagesHeader from '../../Components/PagesHeader';
import { ApiService } from "../../Components/Services/apiservices";
import { Triangle } from 'react-loader-spinner'
let apiServices = new ApiService();
function Vacancies() {
  const didMountRef = useRef(true)
  const { slug } = useParams();
  const [pageData, setPageData] = useState({})
  const [headerImage, setHeaderImage] = useState("")
  const [pageContent, setPageContent] = useState('');
  const [saveApplyData, setSaveApplyData] = useState({ fname: '', phone_number: '', email: '', message: '',file: null, })
  const [successMessage , setSuccessmessage] = useState("")
  const [errorMessage , setErrorMessage] = useState("")
  const [spinnerLoading , setSpinnerLoading] = useState(false)

  useEffect(() => {
    if (didMountRef.current) {
      const dataString = {
        "page_url": "vacancies"
      }
      apiServices.pagePostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          if (res.data.pageData != null) {
            setPageData(res.data.pageData)
            setHeaderImage(res.data.page_header_url)
            setPageContent(res.data.pageData.page_content)

          }
        }
      })
  
    }
    didMountRef.current = false;
  });
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handlechangeData = (e) => {
    const value = e.target.value
    const key = e.target.name
    setSaveApplyData({ ...saveApplyData, [key]: value })
  }
  const submitapplydata = () => {
    if (saveApplyData.fname == "") {
      document.getElementById("fname").style.border = "1px solid red"
      return false
    }
    if (saveApplyData.phone_number == "") {
      document.getElementById("phone_number").style.border = "1px solid red"
      return false
    }
    if (saveApplyData.email == "") {
      document.getElementById("email").style.border = "1px solid red"
      return false
    }
 
    if (!isValidEmail(saveApplyData.email)) {
      document.getElementById("email").style.border = "1px solid red"
      setErrorMessage('Email Address is invalid');
      return false
    }   
    if (saveApplyData.message == "") {
      document.getElementById("message").style.border = "1px solid red"
      return false
    } 
  
setSpinnerLoading(true)
  const formData = new FormData();
  formData.append("fname", saveApplyData.fname);
  formData.append("phone_number", saveApplyData.phone_number);
  formData.append("email", saveApplyData.email);
  formData.append("message", saveApplyData.message);
  formData.append("file", saveApplyData.file);

    apiServices.careerprocessPostRequest(formData).then(res => {
      if(res.data.status=="success"){
        setSuccessmessage(res.data.message)
         setErrorMessage("")
         setSpinnerLoading(false)
        setTimeout(() => {
          document.getElementById("btn_timeout").click()
          window.location.reload();
        }, 3000);
      }
      else{
        setErrorMessage(res.data.message)
       
    }
    })
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSaveApplyData({ ...saveApplyData, file });
  };


  return (
    <>
          <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc} />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Roti Corner" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Roti Corner" />
        {pageData.page_header_image != null ? <meta property="og:image" content={headerImage + pageData.page_header_image} />
          : <meta property="og:image" content={'/assets/img/logo.png'} />}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Roti Corner" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc} />
        {pageData.page_header_image != null ? <meta property="twitter:image" content={headerImage + pageData.page_header_image} />
          : <meta property="twitter:image" content={'/assets/img/logo.png'} />}
        <meta name="twitter:url" content={window.location.href} />
        <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
      </Helmet>
      <PagesHeader />
      <div id="cappa-main">
        <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{ backgroundImage: `url(${headerImage + pageData.page_header_image})`, backgroundSize: 'cover', backgroundPosition: '100%' }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center caption mt-90">
                {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}
              </div>
            </div>
          </div>
        </div>
        <section className='about section-padding'>
          <div className='container'>
            <div className='row text-center'>
                <div >
                <h2 style={{fontSize:"45px"}}><strong>We're Hiring!</strong></h2>
                </div>
                <div>
                    <h5>Join Our Team</h5>
                    <p>If you're interested in one of our open positions in our Kitchen, Front of House or Bar, start by applying here and attaching your resume.</p>
                </div>
                <div className="butn-dark mt-4"> <a data-bs-target="#careerModal" data-bs-toggle="modal" href="#careerModal" ><span>Apply Here</span></a> </div>
           
            </div>
          </div>
        </section>
      </div>

      <div className="modal fade equote" id="careerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content equote-content">
            <button type="button" className="btn-close" id="btn_timeout" data-bs-dismiss="modal" aria-label="Close"></button>
            <h3>Apply Now</h3>
           {successMessage !== '' ? <div className="alert alert-success" role="alert">{successMessage}</div> : null}
                   {errorMessage !==''?<div className="alert alert-success" role="alert" >{errorMessage}</div>:null}
                   {spinnerLoading == false ?
            <div className='row'>
              <div className='col-lg-12'>
                <div className='form-group mb-2'>
                  <label>Name</label>
                  <input type='text' name="fname" id="fname" onChange={handlechangeData}></input>
                </div>
              </div>
              <div className='col-lg-12'>
                <div className='form-group mb-2'>
                  <label>Phone Number</label>
                  <input type='number' name="phone_number" id="phone_number" onChange={handlechangeData}></input>
                </div>
              </div>
         
              <div className='col-lg-12'>
                <div className='form-group mb-2'>
                  <label>Email Id</label>
                  <input type='email' name="email" id="email" onChange={handlechangeData}></input>
                </div>
              </div>
              <div className='col-lg-12'>
                <div className='form-group mb-2'>
                <label>Message</label>
                  <textarea name="message" id="message" cols="30" rows="4" onChange={handlechangeData} required></textarea>
                </div>
              </div>
                    <div className='col-lg-12'>
              <div className='mb-2'>
                <label>Attach Resume (PDF)</label>
                <input
                  type='file'
                  accept=".pdf"
                  name="file"
                  id="file"
                  onChange={handleFileChange}
                />
              </div>
            </div>
                    
              <div className='col-lg-12'>
                <div className="butn-dark mt-3"><button type="button" className='butn-dark-full' onClick={submitapplydata}><span>Submit Application</span></button></div>
              </div>
            </div>
             : <Triangle
             width="100%"
             height="80px"
             align-items="center"
             justify-content="center"
             color="#AA8453"
             ariaLabel="triangle-loading"
             wrapperStyle={{}}
             wrapperClassName="loader"
             visible={true}
           />}
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}
export default Vacancies