import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import Footer from '../../Components/Footer'
import { useParams } from "react-router-dom";
import PagesHeader from '../../Components/PagesHeader'
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function Events() {
    const didMountRef = useRef(true)
    const { slug } = useParams();
    const [pageData, setPageData] = useState({})
    const [headerImage, setHeaderImage] = useState("")
    const [eventData, setEventData] = useState([])
    const [pageContent, setPageContent] = useState('');
    const [fullSubHeading, setFullSubHeading] = useState('');
    useEffect(() => {
        if (didMountRef.current) {
            geteventdata()
          const dataString = {
            "page_url": "events"
          }
          apiServices.pagePostRequest(dataString).then(res => {
            if (res.data.status === "success") {
              if (res.data.pageData != null) {
                setPageData(res.data.pageData)
                setHeaderImage(res.data.page_header_url)
                setPageContent(res.data.pageData.page_content)
              }
            }
          })
        }
        didMountRef.current = false;
      });
      const geteventdata =() => {
        apiServices.eventdataUrlGetRequest().then(res => {
          if(res.data.status == "success"){
            setEventData(res.data.eventData)
          }
        })
      }
  return (
    <>
    <PagesHeader/>
    <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{ backgroundImage: `url(${headerImage + pageData.page_header_image})`, backgroundSize: 'cover', backgroundPosition: '100%' }}>
          <div className="container">
            <div className="row">
            <div className="col-md-12 text-left caption mt-90">
          {pageData.page_sub_header != null ? <h5>{pageData.page_sub_header}</h5> : ""}
          {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}
        </div>
            </div>
          </div>
        </div>
        {pageContent != null ? <div dangerouslySetInnerHTML={{ __html: pageContent }}></div> : ""}
    <section className="about section-padding ">
    <div className="container">
    <div className="row">
    <div className="col-md-12 text-center mb-30">
    <div className="section-title"><span><strong>EVENTS CALENDAR</strong></span></div>
    </div>
    {eventData.map((value)=>(
    <div className="row border-bottom mb-5">
        <div className="col-lg-3">
            <p>Sat - Sun</p>
            <h2>{moment(value.event_start_date).format('MMM D')}th-{moment(value.event_end_date).add(1, 'day').format('D')}th
            </h2>
            {/* <h3>Oct 28th-29th</h3> */}
        </div>
        <div className="col-lg-6">
            <h5>{value.event_name}</h5>
            {/* <p dangerouslySetInnerHTML={{ __html: value.event_desc }}></p> */}
 {fullSubHeading == true ?<div className='mb-2'>
<span dangerouslySetInnerHTML={{ __html: value.event_desc }}></span><span style={{color:"red" , cursor:"pointer"}} onClick={()=>{setFullSubHeading(false)}}> - Events Details</span></div>
:<div className='mb-2'> <span dangerouslySetInnerHTML={{ __html: value.event_desc.substring(0,200)}}></span>{eventData.length > 0 && value.event_desc.substring(0, 235)  ?<span style={{color:"black" , cursor:"pointer"}} onClick={()=>{setFullSubHeading(true)}}>+ Events Details</span>:""}</div>}
        </div>
        <div className="col-lg-3 text-end">
        <h5>
        {moment(value.event_start_time, 'HH:mm:ss').format('HH:mm')}-
        {moment(value.event_end_time, 'HH:mm:ss').format('HH:mm')}
      </h5>
            <p>ROTI CORNER CAMBERLEY</p>
        </div>
</div>
    ))}
</div>
</div>
</section>
    <Footer/>
    </>
  )
}
export default Events