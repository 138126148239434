import React from 'react'

function Resevation() {
    const backgroundUrl = "assets/img/reservation.jpg";

    return (
        <section className="testimonials">
            <div className="background bg-img bg-fixed section-padding pb-0" style={{ backgroundImage: `url(${backgroundUrl})` }} data-overlay-dark="2">
                <div className="container">
                    <div className="row">

                        <div className="col-md-5 mb-30 mt-30">
                            <p><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i
                                className="star-rating"></i><i className="star-rating"></i></p>
                            <h5>Roti Corner offers a contemporary take on traditional Indian cuisine in the heart of Camberley.</h5>
                            <div className="reservations mb-30">
                                <div className="icon color-1"><span className="flaticon-call"></span></div>
                                <div className="text">
                                    <p className="color-1">Reservations</p> <a className="color-1" href="tel:0333 444 3663">0333 444
                                        3663</a>
                                </div>
                            </div>
                            <p><i className="ti-check"></i><small>Call us, it's toll-free.</small></p>
                        </div>

                        <div className="col-md-5 offset-md-2">
                            <div className="booking-box">
                                <div className="head-box">
                                    <h4 className="text-center">Book your reservation for an unforgettable dining experience!</h4>
                                </div>
                                <div className="booking-inner clearfix"  >
                                <div className="butn-dark text-center"><a href='https://eatapp.co/reserve/rotisserie-corner-aa08b4' target='_blank'><span>Book Now</span></a> </div>
                                {/* <iframe
                                    title="Eat Widget"
                                    frameborder="0"
                                    src="https://eatapp.co/reserve/rotisserie-corner-aa08b4?source=iframe"
                                    style={{position: "absolute", top: "0", left: "0", width: "100%", height: "100%"}}
                                    /> */}
                                    {/* <form action="#" className="form1 clearfix">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="input1_wrapper">
                                                    <label>Check in</label>
                                                    <div className="input1_inner">
                                                        <input type="text" className="form-control input datepicker"
                                                            placeholder="Check in" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="input1_wrapper">
                                                    <label>Check out</label>
                                                    <div className="input1_inner">
                                                        <input type="text" className="form-control input datepicker"
                                                            placeholder="Check out" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="select1_wrapper">
                                                    <label>Adults</label>
                                                    <div className="select1_inner">
                                                        <select className="select2 select" style={{ width: "100%" }}>
                                                            <option value="0">Adults</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="select1_wrapper">
                                                    <label>Children</label>
                                                    <div className="select1_inner">
                                                        <select className="select2 select" style={{ width: "100%" }}>
                                                            <option value="0">Children</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <button type="submit" className="btn-form1-submit mt-15">Check
                                                    Availability</button>
                                            </div>
                                        </div>
                                    </form> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Resevation