import React from 'react'

function Services() {
    return (
        <section className="services section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6 p-0 " data-animate-effect="fadeInLeft">
              <div className="img left">
                <a href="javascript:void(0)"><img src="/assets/img/restaurant/barm.png" alt="" /></a>
              </div>
            </div>
            <div className="col-md-6 p-0 bg-cream valign " data-animate-effect="fadeInRight">
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Discover</h6>
                  </div>
                  <h2 className="section-title">The Bar</h2>
                  <p>Discover our vibrant bar at Roti Corner,
                  offering an exquisite selection of inspired cocktails and premium spirits in the heart of London.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 bg-cream p-0 order2 valign " data-animate-effect="fadeInLeft">
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Experiences</h6>
                  </div>
                  <h2 className="section-title">Luxury Dining</h2>
                  <p>Experience exquisite luxury dining with a contemporary Indian twist at Roti Corner,
                  London's premier destination.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0 order1 " data-animate-effect="fadeInRight">
              <div className="img">
                <a href="javascript:void(0)"><img src="assets/img/spa/luxury.png" alt="" /></a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 p-0 " data-animate-effect="fadeInLeft">
              <div className="img left">
                <a href="javascript:void(0)"><img src="assets/img/spa/shisha1.png" alt="" /></a>
              </div>
            </div>
            <div className="col-md-6 p-0 bg-cream valign " data-animate-effect="fadeInRight">
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Modern</h6>
                  </div>
                  <h2 className="section-title">Shisha</h2>
                  <p>Unwind with our premium shisha flavors in a stylish and relaxed setting at Roti Corner, London.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 bg-cream p-0 order2 valign " data-animate-effect="fadeInLeft">
              <div className="content">
                <div className="cont text-left">
                  <div className="info">
                    <h6>Experiences</h6>
                  </div>
                  <h2 className="section-title">Dining & Events</h2>
                  <p>Host unforgettable gatherings with exquisite Indian cuisine and exceptional service at Roti Corner, London.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0 order1 " data-animate-effect="fadeInRight">
              <div className="img">
                <a href="javascript:void(0)"><img src="assets/img/spa/events.png" alt="" /></a>
              </div>
            </div>
          </div>

      







        </div>
      </section>
    )
}

export default Services