import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './Containers/Home';
import Blogs from './Containers/Blogs';
import Contact from './Containers/Contact';
import Pages from './Containers/Pages';
import Shisha from './Containers/Shisha';
import Gallery from './Containers/Gallery';
import Menu from './Containers/Menu';
import GalleryDetailPage from './Containers/GalleryDetailPage';
import BlogDetailPage from './Containers/BlogDetailPage'; 
import Vacancies from './Containers/Vacancies';
import Reservation from './Containers/Reservation';
import Events from './Containers/Events';


function App() {
  return (
    <div className="App">
   <Router>
    <Routes>
    <Route exact path='/' activeClassName="active" element={ <Home /> }/>
    <Route exact path='/blogs' activeClassName="active" element={ <Blogs /> }/>
    <Route exact path='/blogs/category/:slug' activeClassName="active" element={ <Blogs /> }/>
    <Route exact path='/blogs/:slug' activeClassName="active" element={ <BlogDetailPage /> }/>
    <Route exact path='/contact' activeClassName="active" element={ <Contact /> }/>
    <Route exact path='/shisha' activeClassName="active" element={ <Shisha/> }/>
    <Route exact path='/gallery' activeClassName="active" element={ <Gallery/> }/>
    <Route exact path='/gallery/:slug' activeClassName="active" element={ <GalleryDetailPage/> }/>
    <Route exact path='/menu' activeClassName="active" element={ <Menu /> }/>
    <Route exact path='/reservation' activeClassName="active" element={ <Reservation /> }/>
    <Route exact path='/vacancies' activeClassName="active" element={ <Vacancies/> }/>
    <Route exact path='/events' activeClassName="active" element={ <Events/> }/>
   
   

    <Route exact path='/:slug' activeClassName="active" element={<Pages/>} />



    </Routes>
     </Router>



    </div>
  );
}

export default App;
