import React from 'react'

function HomeInstagram() {
  return (
<>
<section className="news section-padding ">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {/* <div className="section-subtitle"><span>Roti Corner Blog</span></div> */}
                        <div className="section-title text-center">
                            <span>Follow Us On Instagram</span></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
    

                    </div>
                </div>
            </div>

        </section>
</>
  )
}

export default HomeInstagram
