import React, { useEffect, useRef, useState } from 'react'
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ApiService } from "../../Components/Services/apiservices";
import PagesHeader from '../../Components/PagesHeader';
import Footer from '../../Components/Footer';
let apiServices = new ApiService();
function Gallery() {
  const didMountRef = useRef(true)
  const { slug } = useParams();
  const [pageData, setPageData] = useState({})
  const [gallaryCategory, setGallaryCategory] = useState([])
  const [gallaryImageUrl, setGallaryImageUrl] = useState("")
  const [headerImage, setHeaderImage] = useState("")

  useEffect(() => {
    if (didMountRef.current) {
      getpagesdata()
      getgallerydata()
    }

    didMountRef.current = false;
  });

  const getpagesdata = () => {

    const dataString = {
      "page_url": "gallery"
    }
    apiServices.pagePostRequest(dataString).then(res => {
      if (res.data.status === "success") {
        if (res.data.pageData != null) {
          setPageData(res.data.pageData)
          setHeaderImage(res.data.page_header_url)
        }
      }
    })

  }

  const getgallerydata = () => {

    apiServices.gallerycategoryGetRequest().then(res => {
      if (res.data.status == "success") {
        setGallaryCategory(res.data.category)
        setGallaryImageUrl(res.data.category_img_url)

      }
    })
  }

  return (
    <>
      <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc} />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Roti Corner" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Roti Corner" />
        {pageData.page_header_image != null ? <meta property="og:image" content={headerImage + pageData.page_header_image} />
          : <meta property="og:image" content={'/assets/img/logo.png'} />}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Roti Corner" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc} />
        {pageData.page_header_image != null ? <meta property="twitter:image" content={headerImage + pageData.page_header_image} />
          : <meta property="twitter:image" content={'/assets/img/logo.png'} />}
        <meta name="twitter:url" content={window.location.href} />
        <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
      </Helmet>
      <PagesHeader />

      <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{ backgroundImage: `url(${headerImage + pageData.page_header_image})`, backgroundSize: 'cover', backgroundPosition: '100%' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 caption mt-90">
              {pageData.page_sub_header != null ? <h5>{pageData.page_sub_header}</h5> : ""}
              {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}
            </div>
          </div>
        </div>
      </div>

      <section className="rooms1 section-padding bg-blck-light" data-scroll-index="2">
        <div className="container">
          <div className="row">
            {gallaryCategory.map((value) => (
              <div className="col-md-4">
                <div className="item">
                  <div className="position-re o-hidden"> <img src={value.category_image != null ? gallaryImageUrl + value.category_image : "/assets/img/defaultimage.png"} alt="" /></div>
                  <div className="con">

                    <h5><a href={`/gallery/${value.category_slug}`}>{value.category_name}</a> </h5>
                    <div className="line"></div>
                    <div className="row facilities">

                      <div className="col col-md-12 text-end">
                        <div className="permalink"><a href={`/gallery/${value.category_slug}`}>View<i className="ti-arrow-right"></i></a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Gallery