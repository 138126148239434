import React, { useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ApiService } from "../../Components/Services/apiservices";
import PagesHeader from '../../Components/PagesHeader';
import Footer from '../../Components/Footer';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
let apiServices = new ApiService();
function Reservation() {
  const didMountRef = useRef(true)
  const [pageData, setPageData] = useState({})
  const [headerImage, setHeaderImage] = useState("")
  const [pageContent, setPageContent] = useState('');
  const [LoadStatus, setLoadStatus] = useState(false);


  useEffect(() => {
    if (didMountRef.current) {
      getpagesdata()
      setTimeout(() => {
        setLoadStatus(true);
      }, 2000);

    }

    didMountRef.current = false;
  });



  const getpagesdata = () => {

    const dataString = {
      "page_url": "reservation"
    }
    apiServices.pagePostRequest(dataString).then(res => {
      if (res.data.status === "success") {
        if (res.data.pageData != null) {
          setPageData(res.data.pageData)
          setHeaderImage(res.data.page_header_url)
          setPageContent(res.data.pageData.page_content)
        }
      }
    })

  }
  return (
    <>
      <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc} />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Roti Corner" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Roti Corner" />
        {pageData.page_header_image != null ? <meta property="og:image" content={headerImage + pageData.page_header_image} />
          : <meta property="og:image" content={'/assets/img/logo.png'} />}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Roti Corner" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc} />
        {pageData.page_header_image != null ? <meta property="twitter:image" content={headerImage + pageData.page_header_image} />
          : <meta property="twitter:image" content={'/assets/img/logo.png'} />}
        <meta name="twitter:url" content={window.location.href} />
        <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
      </Helmet>
      <PagesHeader />

      <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{ backgroundImage: `url(${headerImage + pageData.page_header_image})`, backgroundSize: 'cover', backgroundPosition: '100%' }}>

        <div className="container">
          <div className="row">
            <div className="col-md-12 text-left caption mt-90">
              {pageData.page_sub_header != null ? <h5>{pageData.page_sub_header}</h5> : ""}
              {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}

            </div>
          </div>
        </div>
      </div>

      <section className="about section-padding">
        <div className="container">
          <div className="row text-center">
            <div>
              <h2 style={{ fontSize: "40px" }}>
                <strong>
                  BOOK A TABLE (10+? CALL 0333 444 3663)
                </strong>
              </h2>
              <div className="butn-dark"><a href='https://eatapp.co/reserve/rotisserie-corner-aa08b4' target='_blank'><span>Book Now</span></a> </div>
            </div>


            {/* <div className="booking-table" style={{ position: "relative", paddingBottom: "110%", width: "100%", height: "0" }}>
               
                <div style={{ position: "relative", paddingBottom: "110%", width: "100%", height: "0" }}>
                  <iframe
                    title="Eat Widget"
                    frameborder="0"
                    src="https://eatapp.co/reserve/rotisserie-corner-aa08b4?source=iframe"
                    style={{position: 'absolute',top: '0', left: '0', width: '100%', height: '100%'}}
                  />
                </div> 

            </div> */}
          </div>
        </div>
      </section>;





      {pageContent != null ? <div dangerouslySetInnerHTML={{ __html: pageContent }}></div> : ""}





      <Footer />
    </>
  )
}

export default Reservation