import React, { useEffect, useRef, useState } from 'react'
import { ApiService } from '../../Components/Services/apiservices';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
let apiServices = new ApiService();

function Testimonials() {

    const didMountRef = useRef(true)
    const [testimonialData, setTestimonialData] = useState([])
    const [testimonialImage, setTestimonialImage] = useState("")


    useEffect(() => {
        if (didMountRef.current) {
            getTestimonialData();
        }
        didMountRef.current = false;
    }, []);

    const getTestimonialData = () => {

        apiServices.testimonialsGetRequest().then(res => {
            if (res.data.status == "success") {
                setTestimonialData(res.data.resTestimonialData)
                setTestimonialImage(res.data.testimonial_image_path)
            }
        })
    }

    const testimonialCarouselOptions = {
        loop: true,
        spaceBetween: 15,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            600: {
                slidesPerView: 1,
            },
            1000: {
                slidesPerView: 1,
            },
        },
    };

    const backgroundUrl = "assets/img/slider/testimonial.png";

    return (
        <section className="testimonials">
            <div className="background bg-img bg-fixed section-padding pb-0" style={{ backgroundImage: `url(${backgroundUrl})` }} data-overlay-dark="2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="testimonials-box">
                                <div className="head-box">
                                    <h6>Testimonials</h6>
                                    <h4>What Client's Say?</h4>
                                    <div className="line"></div>
                                </div>
                                <Swiper {...testimonialCarouselOptions}>
                                    {testimonialData.map((testimonial, index) => {
                                        const starsArray = Array.from({ length: Number(testimonial.testimonial_rating) });
                                        return (
                                            <SwiperSlide key={index}>
                                                <p dangerouslySetInnerHTML={{ __html: testimonial.testimonial_desc }}></p>
                                                <div className="info">
                                                    <div className="author-img"> <img src={testimonial.testimonial_image ? testimonialImage + testimonial.testimonial_image : ""} alt="" style={{ width: "70px", height: "70px" }} /> </div>
                                                    <div className="cont">
                                                        <span>
                                                            {starsArray.map((_, index) => (
                                                                <i key={index} className="star-rating"></i>
                                                            ))}
                                                        </span>
                                                        <h6>{testimonial.testimonial_name}</h6>

                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials