import React, { useEffect, useRef, useState } from 'react';
import constant from "../../Components/Services/constant";
import { ApiService } from '../../Components/Services/apiservices';
import { Link } from 'react-router-dom'

let apiServices = new ApiService();

function Menu() {
    const [menuData, setMenuData] = useState([])
    const [imageUrl, setImageUrl] = useState('')
    const [barData, setBarData] = useState({})
    const [wineData, setWineData] = useState({})
    const [cocktailData, setCocktailData] = useState({})
    const [mainData, setMainData] = useState({})
    const [shishaData, setShishaData] = useState({})
    const [kidsData, setKidsData] = useState({})
    const [desertData, setDesertData] = useState({})
    const didMountRef = useRef(true);
    useEffect(() => {
        if (didMountRef.current) {
            getMenuData()

        }
        didMountRef.current = false;
    }, [])

    const getMenuData = () => {
        apiServices.featuredcategoryGetRequest().then(res => {
            if (res.data.status == "success") {
                setBarData(res.data.resCategoryData[0])
                setWineData(res.data.resCategoryData[1])
                setCocktailData(res.data.resCategoryData[2])
                setMainData(res.data.resCategoryData[3])
                setShishaData(res.data.resCategoryData[4])
                setDesertData(res.data.resCategoryData[5])
                setKidsData(res.data.resCategoryData[6])
                setImageUrl(res.data.category_image_path)
            }
        })

    }
    return (


<>

<section className="rooms1 section-padding-medium bg-blck-light" data-scroll-index="2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title">Our Menu</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-6">
              <div className="item">
                <div className="position-re o-hidden"> 
                <a href={imageUrl + shishaData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">
                <img src={shishaData.cat_image ? imageUrl + shishaData.cat_image : constant.DEFAULT_IMAGE} alt="Menu Item" />
                    </a> 
                    </div>
                <div className="con">
                  <h5><a href={imageUrl + shishaData.cat_mobile_image || "#"}>{shishaData.cat_name}</a> </h5>
                  <div className="line"></div>
                  <div className="row facilities">
                    <div className="col col-md-12 text-end ">
                      <div className="permalink"><a href={imageUrl + shishaData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">Details <i className="ti-arrow-right"></i></a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
                  <div className="item">
                <div className="position-re o-hidden"> 
                <a href={imageUrl + desertData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">
                <img src={desertData.cat_image ? imageUrl + desertData.cat_image : constant.DEFAULT_IMAGE} alt="Menu Item" />

                    </a> </div>
              
                <div className="con">
                
                  <h5><a href={imageUrl + desertData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">{desertData.cat_name}</a></h5>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end">
                      <div className="permalink"><a href={imageUrl + desertData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">Details <i className="ti-arrow-right"></i></a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">


            <div className="item">
                <div className="position-re o-hidden"><a href={imageUrl + mainData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">
                <img src={mainData.cat_image ? imageUrl + mainData.cat_image : constant.DEFAULT_IMAGE} alt="Menu Item" />
                    </a> 
                    </div>
                <div className="con">
                  <h5><a href={imageUrl + mainData.cat_mobile_image || "#"}>{mainData.cat_name}</a></h5>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end ">
                      <div className="permalink"><a href={imageUrl + mainData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">Details <i className="ti-arrow-right"></i></a></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-3 col-6">
          

                 <div className="item">
                <div className="position-re o-hidden"> 
                <a href={imageUrl + wineData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">
                <img src={wineData.cat_image ? imageUrl + wineData.cat_image : constant.DEFAULT_IMAGE} alt="Menu Item" />
                </a></div>
                <div className="con">
                  <h5><a href={imageUrl + wineData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">{wineData.cat_name}</a></h5>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end">
                      <div className="permalink"><a href={imageUrl + wineData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">Details <i className="ti-arrow-right"></i></a></div>
                    </div>
                  </div>
                </div>
              </div>





            </div>

            <div className="col-md-6">
              <div className="item">
                <div className="position-re o-hidden"> 
                <a href={imageUrl + barData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">
                <img src={barData.cat_image ? imageUrl + barData.cat_image : constant.DEFAULT_IMAGE} alt="Menu Item" />
                    </a> 
                    </div>
                <div className="con">
                  <h5>  <a href={imageUrl + barData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">{barData.cat_name}</a></h5>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end ">
                      <div className="permalink"><a href={imageUrl + barData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">Details <i className="ti-arrow-right"></i></a></div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-6">
              <div className="item">
                <div className="position-re o-hidden">
                <a href={imageUrl + kidsData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">

                <img src={kidsData.cat_image ? imageUrl + kidsData.cat_image : constant.DEFAULT_IMAGE} alt="Menu Item" />

                     </a> </div>
                <div className="con">
                  <h5>
                  <a href={imageUrl + kidsData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">
                  {kidsData.cat_name}</a></h5>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end ">
                      <div className="permalink"><a href={imageUrl + kidsData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">Details <i className="ti-arrow-right"></i></a></div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>






        {/* <section className="rooms1 section-padding-medium bg-cream" data-scroll-index="1">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
              <h2 className="section-title">Our Menu</h2>
            </div>
                    <div className="col-lg-4" style={{ padding: "0", margin: "0" }}>
                        <div className="lgx-grid-item breakfast">
                            <div className="lgx-single-menu">
                                <a href={imageUrl + shishaData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">
                                    <figure>
                                        <img src={shishaData.cat_image ? imageUrl + shishaData.cat_image : constant.DEFAULT_IMAGE} alt="Menu Item" />

                                        <figcaption>
                                        </figcaption>
                                    </figure>
                                    <div className="menu-info">
                                        <h3 className="title">{shishaData.cat_name}</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="lgx-grid-item dinner" >
                            <div className="lgx-single-menu">
                                <a href={imageUrl + mainData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">
                                    <figure>
                                        <img src={mainData.cat_image ? imageUrl + mainData.cat_image : constant.DEFAULT_IMAGE} alt="Menu Item" />
                                        <figcaption>
                                        </figcaption>
                                    </figure>
                                    <div className="menu-info">
                                        <h3 className="title">{mainData.cat_name}</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4" style={{ padding: "0", margin: "0" }}>
                        <div className="lgx-grid-item lunch">
                            <div className="lgx-single-menu">
                                <a href={imageUrl + barData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">
                                    <figure>
                                        <img className='img-menu' src={barData.cat_image ? imageUrl + barData.cat_image : constant.DEFAULT_IMAGE} alt="Menu Item" />
                                        <figcaption>
                                        </figcaption>
                                    </figure>
                                    <div className="menu-info">
                                        <h3 className="title">{barData.cat_name}</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="lgx-grid-item lunch breakfast" >
                            <div className="lgx-single-menu">
                                <a href={imageUrl + wineData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">
                                    <figure>
                                        <img className='img-menu' src={wineData.cat_image ? imageUrl + wineData.cat_image : constant.DEFAULT_IMAGE} alt="Menu Item" />
                                        <figcaption>
                                        </figcaption>
                                    </figure>
                                    <div className="menu-info">
                                        <h3 className="title">{wineData.cat_name}</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="lgx-grid-item drinks" >
                            <div className="lgx-single-menu">
                                <a href={imageUrl + cocktailData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">
                                    <figure >
                                        <img className='img-menu' src={cocktailData.cat_image ? imageUrl + cocktailData.cat_image : constant.DEFAULT_IMAGE} alt="Menu Item" />
                                        <figcaption>
                                        </figcaption>
                                    </figure>
                                    <div className="menu-info">
                                        <h3 className="title">{cocktailData.cat_name}</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4" style={{ padding: "0", margin: "0" }}>
                        <div className="lgx-grid-item drinks breakfast">
                            <div className="lgx-single-menu">
                                <a href={imageUrl + kidsData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">
                                    <figure>
                                        <img src={kidsData.cat_image ? imageUrl + kidsData.cat_image : constant.DEFAULT_IMAGE} alt="Menu Item" />
                                        <figcaption>
                                        </figcaption>
                                    </figure>
                                    <div className="menu-info">
                                        <h3 className="title">{kidsData.cat_name}</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="lgx-grid-item breakfast" >
                            <div className="lgx-single-menu">
                                <a href={imageUrl + desertData.cat_mobile_image || "#"} target="_blank" rel="noopener noreferrer">
                                    <figure>
                                        <img src={desertData.cat_image ? imageUrl + desertData.cat_image : constant.DEFAULT_IMAGE} alt="Menu Item" />
                                        <figcaption>
                                        </figcaption>
                                    </figure>
                                    <div className="menu-info">
                                        <h3 className="title">{desertData.cat_name}</h3>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        </>
    )
}

export default Menu