import React, { useEffect,useRef, useState,useContext } from 'react'
import { Helmet } from "react-helmet";
import {useParams} from "react-router-dom";
import Footer from '../../Components/Footer'
import PagesHeader from '../../Components/PagesHeader'
import { ApiService } from "../../Components/Services/apiservices";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataContext from '../../Components/Elements/eventContext';

let apiServices = new ApiService();
function Contact() {
    const didMountRef = useRef(true)
    const { slug } = useParams();
    const [pageData , setPageData] = useState({})
    const [headerImage , setHeaderImage] = useState("")
    const [settingData, setSettingData] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [defaultImage, setDefaultImage] = useState('')
    const [Email, setEmail] = useState('');
    const [Mobile, setMobile] = useState('');
    const [Name, setName] = useState('');
    const [Subject, setSubject] = useState('');
    const [Message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessmessage] = useState('')

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isEmailValid  = (email) => {
      return emailRegex.test(email);
    } 

    const mobileRegex = /^[0-9]{10}$/;
 
    const isMobileValid = (mobile) =>{
    return mobileRegex.test(mobile);
    }

  useEffect(() => {
    if (didMountRef.current) { 
      getpagesdata()
}

didMountRef.current = false;
}); 

const getpagesdata = () => {

  const dataString = {
    "page_url" : "contact"
     }
     apiServices.pagePostRequest(dataString).then(res => {
      if(res.data.status === "success"){
          if(res.data.pageData != null){
        setPageData(res.data.pageData)
        setHeaderImage(res.data.page_header_url)
      }
     }
  })

}

const submitmessage = (e) => {
    e.preventDefault();
    if(!isEmailValid(Email)) {
      toast.error("Please Enter a valid Email", {autoClose: 1000});
      return;
    }
    if(!isMobileValid(Mobile)){
     toast.error("Please Enter a valid Mobile Number", {autoclose: 1000})
    return;
    }

    if (Name === "") {
        toast.error("Please enter your Name", { autoClose: 1000 });
        return;
    } else if (Email === "") {
        toast.error("Please enter your Email", { autoClose: 1000 });
        return;
    } else if (Mobile === "") {
        toast.error("Please enter your Mobile Number", { autoClose: 1000 });
        return;
    } else if (Subject === "") {
        toast.error("Please enter the Subject", { autoClose: 1000 });
        return;
    } else if (Message === "") {
        toast.error("Please enter your Message", { autoClose: 1000 });
        return;
    } else {
        setName("");
        setEmail("");
        setMobile("");
        setSubject("");
        setMessage("");
        // toast.success("Message sent successfully", { autoClose: 3000 });

        const dataString = {
            "contact_name": Name,
            "contact_email": Email,
            "contact_mobile": Mobile,
            "contact_subject": Subject,
            "contact_message": Message,
        }
        apiServices.contactusprocessPostRequest(dataString).then(res => {
            console.log(res);
            if (res.data.status === "success") {
                toast.success(res.data.message, { autoClose: 2000 });
            } else if (res.data.status === "error") {
                toast.error(res.data.message, { autoClose: 2000 });
            }
        });
    }
}

const contextValues = useContext(DataContext);

  return (
    <>
    <ToastContainer />
    <Helmet>
    <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc} />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Roti Corner" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Roti Corner" />
        {pageData.page_header_image != null ? <meta property="og:image" content= {headerImage + pageData.page_header_image}/>
                :<meta property="og:image" content= {'/assets/img/logo.png'}/>}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Roti Corner" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc} />
        {pageData.page_header_image != null ? <meta property="twitter:image" content= {headerImage + pageData.page_header_image}/>
                :<meta property="twitter:image" content= {'/assets/img/logo.png'}/>}
        <meta name="twitter:url" content={window.location.href} />
        <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
      </Helmet>
    <PagesHeader/>
    <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{backgroundImage: `url(${headerImage + pageData.page_header_image})`,backgroundSize:'cover',backgroundPosition:'100%'}}>
    {/* <div className="banner-header section-padding valign bg-img bg-fixed" data-overlay-dark="3" data-background="assets/img/slider/contact.jpg"> */}
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                    <h5>Get in touch</h5>
                    <h1>Contact Us</h1>
                </div>
            </div>
        </div>
    </div>

      <section className="contact section-padding">
        <div className="container">
            <div className="row mb-90">
                <div className="col-md-6 mb-60">
                    <h3>Roti Corner</h3>
                    <p dangerouslySetInnerHTML={{__html: pageData.page_content}}></p>
                    <div className="reservations mb-30">
                        <div className="icon"><span className="flaticon-call"></span></div>
                        <div className="text">
                            <p>Reservations</p><a href={"tel:"+ contextValues.settingData.admin_support_mobile} className="footer-contact-phone">{contextValues.settingData.admin_support_mobile}</a>
                        </div>
                    </div>
                    <div className="reservations mb-30">
                        <div className="icon"><span className="flaticon-envelope"></span></div>
                        <div className="text">
                        <p>Email Info</p><a href={"mailto:"+ contextValues.settingData.admin_email}>{contextValues.settingData.admin_email}</a>
                        </div>
                    </div>
                    <div className="reservations">
                        <div className="icon"><span className="flaticon-location-pin"></span></div>
                        <div className="text">
                        <p>Address</p><a href="https://maps.app.goo.gl/zsp4FdrvaACKmSEY9" target="new">{contextValues.settingData.address}
                            <br/></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 mb-30 offset-md-1">
                    <h3>Get in touch</h3>
                    {successMessage != "" ?
                                    <div className="alert alert-success" role="alert">
                                        {successMessage}
                                    </div> : ""}
                                {errorMessage != "" ?
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div> : ""}
                    <form method="post" className="contact__form" action="mail.php">
              
                        <div className="row">
                            <div className="col-12">
                                <div className="alert alert-success contact__msg" style={{display: "none"}} role="alert"> Your message was sent successfully. </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 form-group mb-3">
                                <input name="name" type="text" placeholder="Your Name *" onChange={(e) => setName(e.target.value)} value={Name} required/>
                            </div>
                            <div className="col-md-6 form-group mb-3">
                                <input name="email" type="email" placeholder="Your Email *" onChange={(e) => setEmail(e.target.value)} value={Email} required/>
                            </div>
                            <div className="col-md-6 form-group mb-3">
                                <input name="phone" type="text" placeholder="Your Number *" onChange={(e) =>  setMobile(e.target.value)} value={Mobile} required style={{ color:"#666"}}/>
                            </div>
                            <div className="col-md-6 form-group mb-3">
                                <input name="subject" type="text" placeholder="Subject *"  onChange={(e) => setSubject(e.target.value)} value={Subject} required/>
                            </div>
                            <div className="col-md-12 form-group mb-3">
                                <textarea name="message" id="message" cols="30" rows="4" placeholder="Message *" onChange={(e) => setMessage(e.target.value)} value={Message} required></textarea>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="butn-dark2"  onClick={submitmessage}><span>Send Message</span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 map animate-box" data-animate-effect="fadeInUp">

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2492.5317544516647!2d-0.7509377230979407!3d51.33813182305452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875d554f33ceb1d%3A0x2107dd9ec05d2b19!2sRoti%20Corner%20Bar%20Restaurant%20%26%20Shisha%20Lounge!5e0!3m2!1sen!2sin!4v1696331358305!5m2!1sen!2sin" width={"100%"} height={"600"} style={{border:"0"}} allowFullScreen="" loading={"lazy"}></iframe>
           
                </div>
            </div>
        </div>
    </section>
    <Footer/>
    </>

  )
}

export default Contact