import React, { useEffect, useRef, useState } from 'react'
import { ApiService } from '../../Components/Services/apiservices'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import moment from 'moment';

const apiServices = new ApiService()

function Blogs() {
    const didMountRef = useRef(true)
    const [blogData, setBlogData] = useState([])
    const [blogImageUrl, setBlogImageUrl] = useState("")
    const [defaultImage, setDefaultImage] = useState("")
    useEffect(() => {
        if (didMountRef.current) {
            apiServices.featuredblogsGetRequest().then(res => {
                if (res.data.status === "success") {
                    setBlogData(res.data.blogsData)
                    setBlogImageUrl(res.data.blog_url)
                    setDefaultImage(res.data.default_image_baseurl)
                }

            })
        }
        didMountRef.current = false
    })
    return (
        <section className="news section-padding bg-blck">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        {/* <div className="section-subtitle"><span>Roti Corner Blog</span></div> */}
                        <div className="section-title">
                            <span style={{ color: "#fff" }}>Our Blogs</span></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={3}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                            breakpoints={{

                                340: {
                                    width: 340,
                                    slidesPerView: 0.9,
                                },
                                640: {
                                    width: 640,
                                    slidesPerView: 0.9,
                                },

                                768: {
                                    width: 768,
                                    slidesPerView: 3,
                                },
                                1000: {
                                    width: 1000,
                                    slidesPerView: 6,
                                },
                                1200: {
                                    width: 1200,
                                    slidesPerView: 3.1,
                                },
                            }}
                        >
                            {blogData.map((value, index) => (
                                <SwiperSlide key={index}>
                                    <div className="item">
                                        <div className="position-re o-hidden"><img src={value.blog_image != null ? blogImageUrl + value.blog_image : "/assets/img/defaultimage.png"} alt="" />
                                            <div className="date">
                                                <a href="#"><span>{moment(value.created_at).format('MMM')}</span> <i>{moment(value.created_at).format('DD')}</i></a>
                                            </div>
                                        </div>
                                        <div className="con"> <span className="category">
                                            <a href={`/blogs/${value.blog_slug}`}>{value.blog_category_name}</a>
                                        </span>
                                            <h5><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h5>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                    </div>
                </div>
            </div>

        </section>
    )
}

export default Blogs