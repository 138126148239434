import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import Menu from './Menu'
import PromoVideo from './PromoVideo'
import Services from './Services'
import Testimonials from './Testimonials'
import Blogs from './Blogs'
import Clients from './Clients'
import Resevation from './Resevation'
import { ApiService } from "../../Components/Services/apiservices";
import HomeInstagram from '../HomeInstagram';
let apiServices = new ApiService();

function Home() {
    const didMountRef = useRef(true)
    const [pageData, setPageData] = useState({})
    const [pageContent, setPageContent] = useState('');
    const [headerImage , setHeaderImage] = useState("")

    useEffect(() => {
        if (didMountRef.current) {
            getpagesdata()
        }
        didMountRef.current = false;
      })

      const getpagesdata = () => {

        const dataString = {
          "page_url" : "home"
           }
           apiServices.pagePostRequest(dataString).then(res => {
            if(res.data.status === "success"){
                if(res.data.pageData != null){
              setPageData(res.data.pageData)
              setHeaderImage(res.data.page_header_url)
              setPageContent(res.data.pageData.page_content)
            }
           }
        })
      
      }
  return (
    <>
     <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc :""} />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Roti Corner" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Roti Corner" />
        {pageData.page_header_image != null ? <meta property="og:image" content= {headerImage + pageData.page_header_image}/>
                :<meta property="og:image" content= {'/assets/img/logo.png'}/>}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Roti Corner" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc} />
             {pageData.page_header_image != null ? <meta property="twitter:image" content= {headerImage + pageData.page_header_image}/>
                :<meta property="twitter:image" content= {'/assets/img/logo.png'}/>}
        <meta name="twitter:url" content={window.location.href} />
        <meta name="facebook-domain-verification" content="" />
      </Helmet>
    <Header />
   
    {pageContent != null ? <div dangerouslySetInnerHTML={{ __html: pageContent }}></div> : ""}
    
 
    <Menu/>
{/* <PromoVideo/> */}
<Services/>
<Testimonials/>
<Blogs/>
{/* <HomeInstagram/> */}
<Resevation/>



    <Footer/>
    </>
  )
}

export default Home