import React, { useEffect, useState, useRef, useContext } from 'react';

import { useLocation } from "react-router-dom";
import DataContext from '../Elements/eventContext';


function TopSlider() {
  const location = useLocation();
  const contextValues = useContext(DataContext);
  const didMountRef = useRef(true);

  useEffect(() => {
    if (didMountRef.current) {
    setTimeout(() => {
      didMountRef.current = false;
    }, 1000);
    }
  }, [])
  return (

<>

<header className="header">

                <div className="video-fullscreen-wrap">
                {didMountRef.current && (
            <div className="video-fullscreen-video" data-overlay-dark="6">
              <video autoPlay loop muted playsInline>
                <source src={'/assets/video/roticorner.mp4'} type="video/mp4" />
                <source src={'/assets/video/roticorner.webm'} type="video/webm" />
              </video>
            </div>
          )}
                  {/* {didMountRef && <div className="video-fullscreen-video" data-overlay-dark="6" >
                    <video  src={'/assets/video/roticorner.mp4'} autoplay="autoplay" loop muted playsInline >
                     </video>
                    <video
                      src={slide.slider_video ? sliderImage + slide.slider_video : constant.DEFAULT_IMAGE}
                      loop={true} muted={true} autoplay="autoplay" playsinline={true}
                    >
                    </video>

                    <img 
                      src={slide.slider_image ? sliderImage + slide.slider_image : constant.DEFAULT_IMAGE}
                       autoplay="autoplay" 
                    >
                    </img>

                  </div>} */}
                  
                  <div className="v-middle caption overlay">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-10 offset-md-1">
                          <span>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                            <i className="star-rating"></i>
                          </span>
                          <h4>Welcome to Roti Corner</h4>
                          <h1>Indian kitchen bar and lounge</h1>
                          <div className="butn-dark"><a href="https://eatapp.co/reserve/rotisserie-corner-aa08b4" target='_blank' ><span>Reservations</span></a> </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



        <div className="reservation">
          <a href={"tel:" + contextValues.settingData.admin_support_mobile}>
            <div className="icon d-flex justify-content-center align-items-center">
              <i className="flaticon-call"></i>
            </div>

            <div className="call"><span>{contextValues.settingData.admin_support_mobile}</span> <br />
              {/* Reservations */}
            </div>
          </a>
        </div>

        <div className="arrow bounce text-center">
          <a href="#" data-scroll-nav="1"><i className="ti-arrow-down"></i></a>
        </div>
      </header>


</>

  )
}

export default TopSlider
