import React, { useEffect, useState, useRef, useContext } from 'react';
import { ApiService } from '../Services/apiservices';
import { useLocation } from "react-router-dom";
import DataContext from '../Elements/eventContext';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import constant from '../Services/constant';
import TopSlider from './top_slider';
let apiServices = new ApiService();

function Header() {
  const location = useLocation();
  const contextValues = useContext(DataContext);
  const [sliderData, setSliderData] = useState([])
  const [sliderImage, setSliderImage] = useState("")

  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      apiServices.settingsdataGetRequest().then(res => {
        if (res.data.status == "success") {
          contextValues.setImageUrl(res.data.settings_url)
          contextValues.setSettingData(res.data.sitesettings)
        }
      })
    }
    didMountRef.current = false;
  }, [])


  return (
    <>
      <div className="progress-wrap cursor-pointer">
        <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>

      <nav className="navbar navbar-expand-lg">
        <div className="container">

          <div className="logo-wrapper">
            <a className="logo" href="/"> <img src="assets/img/logo.webp" className="logo-img" alt="" /> </a>

          </div>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"><i className="ti-menu"></i></span> </button>

          <div className="collapse navbar-collapse" id="navbar">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item"><a href="/" className={location.pathname.includes("/") ? "nav-link active" : "nav-link"}>Home</a></li>
              <li className="nav-item"><a href="https://eatapp.co/reserve/rotisserie-corner-aa08b4" className={location.pathname.includes("https://eatapp.co/reserve/rotisserie-corner-aa08b4") ? "nav-link active" : "nav-link"}target='_blank'>Reservations</a></li>
              <li className="nav-item"><a href="/menu" className={location.pathname.includes("/menu") ? "nav-link active" : "nav-link"}>Menu</a></li>
              <li className="nav-item"><a href="https://pay.yoello.com/v/roticorner" className={location.pathname.includes("https://pay.yoello.com/v/roticorner") ? "nav-link active" : "nav-link"} target='_blank'>Order Food</a></li>
              <li className="nav-item"><a href="/events" className={location.pathname.includes("/events") ? "nav-link active" : "nav-link"}>Events</a></li>
              <li className="nav-item"><a href="/about-us" className={location.pathname.includes("/about-us") ? "nav-link active" : "nav-link"}>About</a></li>
              <li className="nav-item"><a href="/shisha" className={location.pathname.includes("/shisha") ? "nav-link active" : "nav-link"}>Shisha</a></li>


              <li className="nav-item dropdown"> <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">More <i className="ti-angle-down"></i></a>
                <ul className="dropdown-menu">
                  <li><a href="/gallery/roti-corner-gallery" className="dropdown-item"><span>Gallery</span></a></li>
                  <li><a href="/vacancies" className="dropdown-item"><span>Vacancies</span></a></li>
                  <li><a href="/contact" className="dropdown-item"><span>Contact Us</span></a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
   <TopSlider/>



    </>









  )
}

export default Header