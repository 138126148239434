import React, { useEffect, useState, useRef, useContext } from "react";
import { ApiService } from "../Services/apiservices";
import DataContext from "../Elements/eventContext";
import { BrowserView, MobileView } from "react-device-detect";

let apiServices = new ApiService();
function Footer() {
  const contextValues = useContext(DataContext);
  const [footer1, setFooter1] = useState("");
  const [footer2, setFooter2] = useState("");
  const [footer3, setFooter3] = useState("");
  const [Email, setEmail] = useState("");
  const [successMessage, setSuccessmessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const didMountRef = useRef(true);
  const [whatsappUrl, setWhatsappUrl] = useState("");
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  useEffect(() => {
    if (didMountRef.current) {
      getFooterData();
      apiServices
        .settingsdataGetRequest()
        .then((res) => {
          if (res.data.status === "success") {
            const adminWhatsappNo = res.data.sitesettings?.admin_whatsapp_no;
            if (adminWhatsappNo) {
              setWhatsappUrl("https://wa.me/" + adminWhatsappNo);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching settings data:", error);
        });
    }
    didMountRef.current = false;
  }, []);

  const getFooterData = () => {
    apiServices.footerDataGetRequest().then((res) => {
      if (res.data.status == "success") {
        setFooter1(res.data.footerData.footer_desc1);
        setFooter2(res.data.footerData.footer_desc2);
        setFooter3(res.data.footerData.footer_desc3);
      }
    });
  };

  const submitmessage = (e) => {
    e.preventDefault();
    if (Email === "") {
      document.getElementById("newsletter_email").style.border =
        "1px solid red";
      setEmail("");
      return false;
    }
    if (!isValidEmail(Email)) {
      document.getElementById("newsletter_email").style.border =
        "1px solid red";
      setErrorMessage("Email is invalid");
      return false;
    }
    setErrorMessage("");
    document.getElementById("newsletter_email").style.border =
      "1px solid #aa8453";
    const dataString = {
      newsletter_email: Email,
    };

    apiServices.newsletterPostRequest(dataString).then((res) => {
      if (res.data.status === "success") {
        setSuccessmessage(res.data.message);
        setEmail("");
      } else {
        setErrorMessage(res.data.message);
        setEmail("");
      }

      setTimeout(() => {
        document.getElementById("btn_timeout").click();
        window.location.reload();
      }, 2000);
    });
  };
  const style_web = {
    position: 'fixed',
    bottom: '85px',
    right: '24px',
    zIndex: '9999'
  };
  const style_mobile = {
    position: 'fixed',
    bottom: '22px',
    right: '26px',
    zIndex: '9999'
  };



  return (
    <>
      <BrowserView>
        <footer className="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="footer-column footer-about">
                    <div
                      className="footer-logo"
                      style={{ marginBottom: "10px" }}
                    >
                      <a className="logo" href="/">
                        {" "}
                        <img
                          src="/assets/img/logo.webp"
                          className="logo-img"
                          alt=""
                        />{" "}
                      </a>
                    </div>
                    <h3 className="footer-title">Roti Corner</h3>
                    <p
                      className="footer-about-text"
                      dangerouslySetInnerHTML={{ __html: footer1 }}
                    ></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div
                      className="col-md-6 col-6"
                      dangerouslySetInnerHTML={{ __html: footer2 }}
                    ></div>
                    <div
                      className="col-md-6 col-6"
                      dangerouslySetInnerHTML={{ __html: footer3 }}
                    ></div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="newsletterbox">
                        <h4 className="footer-title">Newsletter</h4>
                        {successMessage !== "" ? (
                          <div
                            className="alert alert-success"
                            role="alert"
                            style={{ color: "white" }}
                          >
                            {successMessage}
                          </div>
                        ) : null}
                        {errorMessage !== "" ? (
                          <div
                            className="alert alert-danger"
                            role="alert"
                            style={{ color: "white" }}
                          >
                            {errorMessage}
                          </div>
                        ) : null}

                        <div className="input-group footer-subscription-form">
                          <input
                            type="email"
                            className="form-control"
                            id="newsletter_email"
                            placeholder="Your email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={Email}
                          ></input>
                          <button
                            className="btn01"
                            type="button"
                            id="btn_timeout"
                            onClick={submitmessage}
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="footer-column footer-contact">
                    <h3 className="footer-title">Contact</h3>

                    <div className="footer-contact-text">
                      <a
                        href="https://maps.app.goo.gl/zsp4FdrvaACKmSEY9"
                        target="new"
                      >
                        <span className="flaticon-marker me-2"></span>
                        {contextValues.settingData.address}
                      </a>
                    </div>
                    <div className="footer-contact-info">
                      <a
                        href={
                          "tel:" +
                          contextValues.settingData.admin_support_mobile
                        }
                        style={{ marginRight: "16px" }}
                      >
                        <span className="flaticon-call me-2"></span>
                        {contextValues.settingData.admin_support_mobile}
                      </a>

                      <a
                        href={"mailto:" + contextValues.settingData.admin_email}
                      >
                        <span className="flaticon-envelope me-2"></span>
                        {contextValues.settingData.admin_email}
                      </a>
                    </div>
                    <div className="footer-about-social-list">
                      {contextValues.settingData.facebook_url != null ? (
                        <a
                          href={contextValues.settingData.facebook_url}
                          target="new"
                        >
                          <i className="ti-facebook"></i>
                        </a>
                      ) : null}
                      {contextValues.settingData.twitter_url != null ? (
                        <a
                          href={contextValues.settingData.twitter_url}
                          target="new"
                        >
                          <i className="ti-twitter"></i>
                        </a>
                      ) : null}

                      {contextValues.settingData.linkedin_url != null ? (
                        <a
                          href={contextValues.settingData.linkedin_url}
                          target="new"
                        >
                          <i className="ti-linkedin"></i>
                        </a>
                      ) : null}
                      {contextValues.settingData.instagram_url != null ? (
                        <a
                          href={contextValues.settingData.instagram_url}
                          target="new"
                        >
                          <i className="ti-instagram"></i>
                        </a>
                      ) : null}

                      {contextValues.settingData.youtube_url != null ? (
                        <a
                          href={contextValues.settingData.youtube_url}
                          target="new"
                        >
                          <i className="ti-youtube"></i>
                        </a>
                      ) : null}

                      {contextValues.settingData.pinterest_url != null ? (
                        <a
                          href={contextValues.settingData.pinterest_url}
                          target="new"
                        >
                          <i className="ti-pinterest"></i>
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer-bottom-inner">
                    <p className="footer-bottom-copy-right">
                      © Copyright 2024 by <a href="/">Roti Corner</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div style={style_web}>
          <a href={whatsappUrl} target="new">
            <img src="/assets/img/whatsapp1.png" style={{width: "52px"}}/>
          </a>
        </div>
      </BrowserView>

      <MobileView>
        <footer className="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="footer-column footer-about">
                    <div
                      className="footer-logo"
                      style={{ marginBottom: "10px" }}
                    >
                      <a className="logo" href="/">
                        {" "}
                        <img
                          src="/assets/img/logo.webp"
                          className="logo-img"
                          alt=""
                        />{" "}
                      </a>
                    </div>
                    <h3 className="footer-title">Roti Corner</h3>
                    <p
                      className="footer-about-text"
                      dangerouslySetInnerHTML={{ __html: footer1 }}
                    ></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div
                      className="col-md-6 col-6"
                      dangerouslySetInnerHTML={{ __html: footer2 }}
                    ></div>
                    <div
                      className="col-md-6 col-6"
                      dangerouslySetInnerHTML={{ __html: footer3 }}
                    ></div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="newsletterbox">
                        <h4 className="footer-title">Newsletter</h4>
                        {successMessage !== "" ? (
                          <div
                            className="alert alert-success"
                            role="alert"
                            style={{ color: "white" }}
                          >
                            {successMessage}
                          </div>
                        ) : null}
                        {errorMessage !== "" ? (
                          <div
                            className="alert alert-danger"
                            role="alert"
                            style={{ color: "white" }}
                          >
                            {errorMessage}
                          </div>
                        ) : null}

                        <div className="input-group footer-subscription-form">
                          <input
                            type="email"
                            className="form-control"
                            id="newsletter_email"
                            placeholder="Your email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={Email}
                          ></input>
                          <button
                            className="btn01"
                            type="button"
                            id="btn_timeout"
                            onClick={submitmessage}
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="footer-column footer-contact">
                    <h3 className="footer-title">Contact</h3>

                    <div className="footer-contact-text">
                      <a
                        href="https://maps.app.goo.gl/zsp4FdrvaACKmSEY9"
                        target="new"
                      >
                        <span className="flaticon-marker me-2"></span>
                        {contextValues.settingData.address}
                      </a>
                    </div>
                    <div className="footer-contact-info">
                      <a
                        href={
                          "tel:" +
                          contextValues.settingData.admin_support_mobile
                        }
                        style={{ marginRight: "16px" }}
                      >
                        <span className="flaticon-call me-2"></span>
                        {contextValues.settingData.admin_support_mobile}
                      </a>

                      <a
                        href={"mailto:" + contextValues.settingData.admin_email}
                      >
                        <span className="flaticon-envelope me-2"></span>
                        {contextValues.settingData.admin_email}
                      </a>
                    </div>
                    <div className="footer-about-social-list">
                      {contextValues.settingData.facebook_url != null ? (
                        <a
                          href={contextValues.settingData.facebook_url}
                          target="new"
                        >
                          <i className="ti-facebook"></i>
                        </a>
                      ) : null}
                      {contextValues.settingData.twitter_url != null ? (
                        <a
                          href={contextValues.settingData.twitter_url}
                          target="new"
                        >
                          <i className="ti-twitter"></i>
                        </a>
                      ) : null}

                      {contextValues.settingData.linkedin_url != null ? (
                        <a
                          href={contextValues.settingData.linkedin_url}
                          target="new"
                        >
                          <i className="ti-linkedin"></i>
                        </a>
                      ) : null}
                      {contextValues.settingData.instagram_url != null ? (
                        <a
                          href={contextValues.settingData.instagram_url}
                          target="new"
                        >
                          <i className="ti-instagram"></i>
                        </a>
                      ) : null}

                      {contextValues.settingData.youtube_url != null ? (
                        <a
                          href={contextValues.settingData.youtube_url}
                          target="new"
                        >
                          <i className="ti-youtube"></i>
                        </a>
                      ) : null}

                      {contextValues.settingData.pinterest_url != null ? (
                        <a
                          href={contextValues.settingData.pinterest_url}
                          target="new"
                        >
                          <i className="ti-pinterest"></i>
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer-bottom-inner">
                    <p className="footer-bottom-copy-right">
                      © Copyright 2024 by <a href="/">Roti Corner</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div style={style_mobile}>
          <a href={whatsappUrl} target="new">
            <img src="/assets/img/whatsapp1.png" style={{width: "52px"}} />
          </a>
        </div>
      </MobileView>
    </>
  );
}

export default Footer;
