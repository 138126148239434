import React, { useEffect,useRef, useState } from 'react'
import {useParams} from "react-router-dom";
import { Helmet } from "react-helmet";
import { ApiService } from "../../Components/Services/apiservices";
import PagesHeader from '../../Components/PagesHeader';
import Footer from '../../Components/Footer';
let apiServices = new ApiService();

function Menu() {
  const ref = useRef(null)
  const didMountRef = useRef(true)
  const { slug } = useParams();
  const [pageData , setPageData] = useState({})
  const [headerImage , setHeaderImage] = useState("")
  const [menuCategory, setMenuCategory] = useState([])
  const [categorySlug, setCategorySlug] = useState("")
  const [categoryBannerImage , setCategoryBannerImage] = useState("")
  const [menuImageUrl , setMenuImageUrl] = useState("") 
  const [menuData , setmenuData] = useState([])

    useEffect(() => {
        if (didMountRef.current) { 
    
          getpagesdata()

          apiServices.menucategoryGetRequest().then(res => {
            if (res.data.status == "success") {
                setCategorySlug(res.data.category[0].category_slug)
                setMenuCategory(res.data.category)
                setCategoryBannerImage(res.data.category.cat_mobile_image)
                setMenuImageUrl(res.data.category_url)
                setmenuData(res.data.menuCategory)
      }
      }) 
    
    }
    
    didMountRef.current = false;
    }); 



    const getpagesdata = () => {

  const dataString = {
    "page_url" : "menu"
     }
     apiServices.pagePostRequest(dataString).then(res => {
      if(res.data.status === "success"){
          if(res.data.pageData != null){
        setPageData(res.data.pageData)
        setHeaderImage(res.data.page_header_url)
      }
     }
  })

}

  return (
<>
<Helmet>
                <title>{pageData.page_meta_title}</title>
                <meta name="description" itemprop="description" content={pageData.page_meta_desc} />
                <meta name="keywords" content={pageData.page_meta_keyword} />
                <link rel="canonical" href={window.location.href} />
                <meta name="robots" content="index,follow" />
                <meta name="author" content="Roti Corner" />
                <meta property="og:title" content={pageData.page_meta_title} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Roti Corner" />
                {pageData.page_header_image != null ? <meta property="og:image" content={headerImage + pageData.page_header_image} />
                    : <meta property="og:image" content={'/assets/img/logo.png'} />}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:description" content={pageData.page_meta_desc} />
                <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@Roti Corner" />
                <meta name="twitter:title" content={pageData.page_meta_title} />
                <meta name="twitter:description" content={pageData.page_meta_desc} />
                {pageData.page_header_image != null ? <meta property="twitter:image" content={headerImage + pageData.page_header_image} />
                    : <meta property="twitter:image" content={'/assets/img/logo.png'} />}
                <meta name="twitter:url" content={window.location.href} />
                <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
            </Helmet>
<PagesHeader/>
    <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{ backgroundImage: `url(${headerImage + pageData.page_header_image})`, backgroundSize: 'cover', backgroundPosition: '100%' }}>
    <div className="container">
        <div className="row">
            <div className="col-md-12  caption mt-90">
                {pageData.page_sub_header != null ? <h5>{pageData.page_sub_header}</h5> : ""}
                {pageData.page_header != null ? <h1>{pageData.page_header}</h1> : ""}
            </div>
        </div>
    </div>
</div>


<section id="menu" className="restaurant-menu menu section-padding pt-0" ref={ref}>
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-md-12 text-center">
                                <div className="section-title" style={{ marginTop: "50px" }}><span><strong>Kitchen | Bar | Shisha Menus</strong></span></div>
                            </div> */}
                        </div>
                        <div className='row mt-5'>
                            <div className='col-lg-12'>
                               
                                <div className='mlist'>
                                    <ul>
                                    {menuCategory && menuCategory.length > 0 && menuCategory.map((value) => (
                                  <li style={{color:"#aa8453", cursor:"pointer", fontSize:"30px"}} className='butn-dark'>
                                    <a href={menuImageUrl + value.cat_mobile_image} target='_blank' className='gapmanage'>
                                      <span>{value.cat_name}</span>
                                    </a>
                                  </li>
                                  ))}


                                    </ul>
                                 
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


                <section id="menu" className="restaurant-menu menu section-padding pt-0" ref={ref}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">

                                <div className="section-title" style={{ marginTop: "50px" }}><span><strong>FROM OUR KITCHEN</strong></span></div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12'>
                                 <div className="restaurant-menu-content col-md-12 mt-5" >
                                 {menuData && menuData.length > 0 && menuData.map((value) => {
  if (value.menu.length > 0) {
    return (
      <>
        <div className='row text-center mb-5'>
          <div className='col-md-12'>
            <div className="section-title"><span>{value.cat_name}</span></div>
          </div>
        </div>
        <div className='row'>
          {value.menu.map((subvalue) => (
            <div className='col-lg-4'>
              <div className="privbox boxgap">
                <div className='mb-3'> <img src={subvalue.product_image} alt="Image" /></div>
                <div className="section-title" style={{ fontSize: "30px" }}><span>{subvalue.product_name}</span></div>
                <p>{subvalue.product_description}</p>
              </div>
            </div>
               ))}
        </div>
      </>
    );
  } 
})}

                                    </div>
                            </div>
                            
                        </div>
                      
                    </div>
                </section>



<Footer/>
</>
  )
}

export default Menu